<template>
    <client-page class="wrap--mypage">
        <v-container>
            <div class="mypage-container">
                <!-- 네비게이션 메뉴 -->
                <mypage-navigation></mypage-navigation>
                <!-- 페이지 콘텐츠 -->
                <div class="mypage-contents">
                    <div class="tit-wrap tit-wrap--unberline">
                        <h2 class="tit">1:1문의</h2>
                    </div>
                    <router-view name="question"></router-view>
                </div>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
	components: {
        ClientPage,
		MypageNavigation
	},
	mounted() {
		this.init();
	},
	methods: {
		init: function() {
			if(!this.accessToken){
				this.$router.push('/login');
			}
		}
	},
	computed: {
		accessToken(){
			return this.$store.state.accessToken;
		},
		payload(){
			return this.$store.state.payload || {};
		},
		scope(){
			return this.payload.scope || [];
		}
	}
}
</script>
