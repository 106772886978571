var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--mypage"
  }, [_c('v-container', [_c('div', {
    staticClass: "mypage-container"
  }, [_c('mypage-navigation'), _c('div', {
    staticClass: "mypage-contents"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--unberline"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("1:1문의")])]), _c('router-view', {
    attrs: {
      "name": "question"
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }